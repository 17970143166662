import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function Confirm({children, onConfirm, open, setOpen, startDecoration, title}) {


  const handleConfirm = (confirmed) => {
    confirmed && onConfirm();
    setOpen(false);
  };


  return (


      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
          {startDecoration || <WarningRoundedIcon />}
            {title || 'Confirmation'}
          </DialogTitle>
          <Divider />
          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions>
          <Button variant="solid" color="danger" onClick={() => handleConfirm(true)}>
             OK
            </Button>
          <Button variant="plain" color="neutral" onClick={() => handleConfirm(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

  );
}