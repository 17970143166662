
import { UltravoxSession } from 'ultravox-client';
import { Room, RoomEvent } from 'livekit-client';
import { api } from '../lib/client';

const backend = new URL(process.env.REACT_APP_BACKEND_SERVER);

let session, room;


const transformFunctions = (functions) => functions?.map?.(({ name, description, parameters, url, implementation, method, key }) => {
  return {
    name,
    description,
    url,
    implementation,
    method,
    key,
    input_schema: {
      type: "object",
      properties:
        parameters?.reduce((o, p) => ({
          ...o, [p.name]: {
            type: p.type,
            in: (url.includes(`{${p.name}}`) ? 'path' : (method === 'post' ? 'body' : 'query')),
            description: p.description
          }
        }), {}) || {},
    }
  };
});


export async function createAgent({ modelName, prompt, options, functions, keys, onClose, onMessage, audioModel, voice }) {
  console.log({ modelName, prompt, options, functions, keys, onClose, onMessage, audioModel }, 'createAgent');
  let { data } = await api.post('/agents', { modelName, prompt, options, functions: transformFunctions(functions), keys });
  let { id } = data;
  console.log({ id, data }, 'agent create id');
  ({ data } = await api.post(`/agents/${id}/listen`, { number: audioModel ? undefined : '*', options: { streamLog: true } }));
  console.log({ id, data }, 'agent create id');
  let { livekit, ultravox, socket } = data || {};
  if (socket) {
    let wsPath = `${backend.protocol === 'https:' ? 'wss:' : 'ws:'}//${backend.host}${socket}`;
    console.log({ id, data, wsPath }, 'opening socket');
    let ws = new WebSocket(wsPath);
    console.log({ wsPath, ws }, 'wsPath');
    ws.addEventListener('message', async (message) => {
      try {
        console.log({ message }, 'socket message');
        data = JSON.parse(message.data);
        onMessage && onMessage(data);
      }
      catch (e) {
      }
    });
    ws.addEventListener('error', (err) => {
      console.error(err, 'ws error');
    });
    ws.addEventListener('close', (err) => {
      onClose && onClose(err.message);
    });
    data.ws = ws;
  }
  if (ultravox) {
    const debugMessages = new Set(["debug"]);
    // sparse array of function calls
    let calls = [];
    let transcriptLen = 0;
    session = new UltravoxSession({ experimentalMessages: debugMessages });
    let state = session.joinCall(ultravox.joinUrl);
    ['ultravoxSessionStatusChanged', 'ultravoxTranscriptsChanged', 'ultravoxExperimentalMessage']
      .forEach(event => state.addEventListener(
        event,
        (msg) => {
          let { transcripts, message } = msg;
          let call;
          console.log({ transcripts, msg, calls, transcriptLen }, 'transcripts');
          let debug = message && message.type && message.message;
          if (debug && debug.startsWith('LLM response: \nTool calls:')) {
            let [[, method, body]] = debug.matchAll(/FunctionCall\(name='([^']*)'.*args='([^']*)'.*\)/ig).toArray() || [[]];
            call = { rest_callout: { method, body, url: "" } };
          }
          else if (debug && debug.startsWith('Tool call complete. Result: ')) {
            let [[, body, name]] = debug.matchAll(/.*role: MESSAGE_ROLE_TOOL_RESULT[\s\S]*text: "(.*)"[\s\S]*tool_name: "(.*)"/ig).toArray() || [[]];
            call = { function_results: [{ name, input: [], result: body.replace(/\\/g, '') }] };
          }
          call && (calls[transcriptLen] = (calls[transcriptLen] || [])) && calls[transcriptLen].push(call);
          transcripts && onMessage && onMessage(transcripts.map((t, ind) => ([{ [t.speaker]: t.text }, ...(calls[ind] || [])])).flat());
          transcriptLen = (transcripts && transcripts?.length - 1) || transcriptLen;
        }
      ));
    data.ws = true;
  }
  else if (livekit) {
    let { serverUrl, participantToken } = livekit;
    let audioElement = new Audio();
    room = new Room({
      disconnectOnPageLeave: true
    });

    room
      .on(RoomEvent.TrackSubscribed, (track) => track.attach(audioElement))
      .on(RoomEvent.TrackUnsubscribed, (track) => track.detach())
      .on(RoomEvent.DataReceived, (payload, participant) => console.log({ payload, participant }, 'data received'))
      .on(RoomEvent.Disconnected, (reason) => {
        console.log({ reason }, 'disconnected');
      })
      .on(RoomEvent.DataReceived, (payload, participant, kind) => {
        //const data = JSON.parse(decoder.decode(payload));
        //console.log({ data, participant, kind }, 'data received');
        //onMessage && onMessage(data); 
      });
    await room.connect(serverUrl, participantToken);
    room.localParticipant.setMicrophoneEnabled(true);
    audioElement.play();
    data.ws = true;
  }


  return { ...data, id, listenerId: data.id };
}

export async function listModels() {
  let { data } = await api.get('/models');
  console.log({ data }, 'models');
  return Object.entries(data);
}

export async function updateAgent({ id, prompt, options, functions, keys }) {
  let { data } = await api.put(`/agents/${id}`, { prompt, options, functions: transformFunctions(functions) });
  return data;
}

export async function deleteAgent({ id, listenerId }) {
  try {
    console.log({ id, session }, 'deleteAgent');
    if (session) {
      session.leaveCall();
      session = null;
    }
    if (room) {
      room.disconnect();
      room = null;
    }
    listenerId && await api.delete(`/agents/${id}/listen/${listenerId}`);
    let { data } = id && await api.delete(`/agents/${id}`);
    return data;
  }
  catch (e) {
    // we may be trying to delete an agent because of a failed network, don't care too much
  }
}

export async function listVoices() {
  let { data } = await api.get('/voices');
  return data;
}