import { initializeApp } from 'firebase/app';
import * as firebase from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { gapi, loadAuth2 } from "gapi-script";

let googleAuth, githubProvider, googleProvider, auth, storage;


const scopes = [
];

if (process.env.REACT_APP_AUTHENTICATE_USERS !== "NO") {

  googleAuth = loadAuth2(
    gapi,
    process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scopes.join(" ")
  );

  githubProvider = new firebase.GithubAuthProvider();

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
    authDomain: "llm-voice.firebaseapp.com",
    projectId: "llm-voice",
    storageBucket: "llm-voice-user-data",
  };

  const app = initializeApp(firebaseConfig);
  auth = firebase.getAuth(app);
  googleProvider = new firebase.GoogleAuthProvider();

  storage = getStorage(app);
  // add Google Sheets permissions
  scopes.forEach((scope) => googleProvider.addScope(scope));

}

export { firebase, auth, googleProvider, googleAuth, githubProvider, storage };
