import React from "react";

const AuthContext = React.createContext();

const nullAuth = (process.env.REACT_APP_AUTHENTICATE_USERS === "NO");

let firebase, auth;

if (!nullAuth) {
  ({ firebase, auth } = await import('../lib/firebase'))
}

function AuthProvider(props) {
  const [status, setStatus] = React.useState("loading");
  React.useEffect(() => {
    if (nullAuth) {
      setStatus("loggedIn");
    }
    else {
      // onAuthStateChanged is called when Firebase's logged in state changes
      // if we have a user then we know we're logged in, otherwise we're not
      const unsubscribe = firebase.onIdTokenChanged(auth, (user) => {
        let s = user ? "unverified" : "loggedOut";
        setStatus(user?.emailVerified ? "loggedIn" : s);
      });
      // onAuthStateChanged returns an unsub function for cleaning up the effect
      return unsubscribe;
    }
  }, []);
  return <AuthContext.Provider {...props} value={status} />;
}

function useAuth() {
  const status = React.useContext(AuthContext);
  if (status === undefined)
    throw new Error("useAuth must be called within an AuthProvider");
  return status;
}

export { AuthProvider, useAuth };
