import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/joy/Box';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import ColourSchemeToggle from './ColourSchemeToggle';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/joy/Tooltip';
import { useUser } from '../../api/user';
import { Link } from '@mui/joy';

const solidPages = {
  '/': true,
  '/api': true
}

function LogoutControl({ ...props }) {
  return <Tooltip title="Logout" {...props}>
    <LogoutIcon {...props} />
  </Tooltip>;
}



export default function Header(props) {
  let { email, logout } = useUser() || {};
  let path = useLocation();
  return (
    <Sheet
      sx={{
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 9995,
        py: 1,
        px: 2,
        gap: 1,
        backgroundColor: !solidPages[path.pathname] && 'transparent',
        boxShadow: !!solidPages[path.pathname] === '/' && 'sm',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
            '--Logo-height': '35px',
          },
        })}
      />
      <Box sx={{ mt: 1, mr: 5 }}>
        <Link href={path?.pathname === '/' ? '/api' : '/'}>
          <img src="/logo.png" height={35} alt="Aplisay logo" />
        </Link>
      </Box>
      <Typography
        sx={{ mr: 'auto' }}
        fontWeight="lg"
      >
        {path?.pathname !== '/landing' && (path?.pathname === '/api' ? `LLM Voice API` : `LLM Voice Playground`)}
      </Typography>
      {email && <Typography
        sx={{ mr: 5, alignItems: 'flex-start' }}
        startDecorator={<LogoutControl onClick={logout}/>} 
        fontWeight="sm"
      >
        {email}
      </Typography>}
      <ColourSchemeToggle />
    </Sheet>
  );
}
