import React from 'react';
import { storage } from '../../lib/firebase'; // Assuming you have your Firebase configuration set up
import { ref, uploadBytes } from 'firebase/storage';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Link from '@mui/joy/Link';
import { useUser } from '../../api/user';

const saveFile = async (fileName, uid, data) => {
  try {
    const fileRef = ref(storage, `/users/${uid}/files/${fileName}`);
    const fileBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    await uploadBytes(fileRef, fileBlob);
  } catch (error) {
    console.error('Error saving file:', error);
  }
};

export default function DeployModal ({ open, setOpen }) {
  let user = useUser();

  const handleWaitlist = async () => {
    let date = new Date();
    saveFile(`waitlist-${date.valueOf()}`, user.uid, {
      date: date.toISOString(),
      name: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      uid: user.uid,
      waitlist: true,
    });
    setOpen(false);
  };

  return <>
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          component="h1"
          id="modal-title"
          level="title-md"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Deploy Options
        </Typography>
        <Typography level="body-md" textColor="inherit" sx={{ m: 1 }}>
          As this service moves from experimental to production, we will soon offer a paid one stop develop-&gt;deploy pipeline
          with number hosting and service provision for small scale pilot applications. This will be offered by us in the UK and other partners around the world for global numbers.
        </Typography>

        <Button sx={{ m: 1 }} onClick={handleWaitlist}>Add me to the deploy waitlist!</Button>
        <Typography level="body-md" textColor="inherit" sx={{ m: 1 }}>
          The Aplisay architecture is entirely Open Source. You can download the <Link href="https://github.com/aplisay/llm-agent" target="_blank">llm-agent</Link> software, deploy it on your own infrastructure with your own phone numbers to run a production instance of the JSON agent specification saved by this builder at any time.
        </Typography>
        <Divider />
        <Typography level="body-md" textColor="inherit" sx={{ m: 1 }}>
          If you have an application at scale that you would like to deploy <b>now</b>, or are a telephony or CPaaS provider looking to deliver Aplisay as a service to your customers, then please <Link variant="outlined" href="mailto:hello@aplisay.com" target="_blank">contact us</Link>.
        </Typography>
      </Sheet>
    </Modal>
  </>;
};
