
import Textarea from '@mui/joy/Textarea';
import Tooltip from '../common/Tooltip';


export default function PromptInput({ prompt, setPrompt, modelName, agents, tooltip, endDecorator }) {


  const promptChange = async (evt) => {
    setPrompt({ value: evt.target.value, changed: true, changedSinceCreate: true });
  };


  return (
    <Tooltip {...{ tooltip }} open={!!tooltip}>
      <Textarea placeholder="Enter prompt here" value={prompt.value} minRows={4} name="prompt" onChange={promptChange} {...{endDecorator}} />
      </Tooltip>
  );
};