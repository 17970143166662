import { useState } from "react";
import { Box, Button, DialogTitle, Drawer, ModalClose, Sheet } from "@mui/joy";
export default function FloatingDrawer({ title, canClose, children, variant, color, startDecorator, buttonText, disabled, sx }) {
  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ display: 'flex', mt: 'auto' }}>
      <Button {...{ variant, color, disabled, startDecorator } } onClick={() => setOpen(true)} sx={{ ...sx, mt: 'auto' }}>
        {buttonText}
      </Button>
      <Drawer
        size="lg"
        variant="plain"
        open={open}
        onClose={() => canClose && setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: 'transparent',
              p: { md: 3, sm: 0, pt: 3 },
              boxShadow: 'none',
            },
          },
        }}
      >
        {open && <Sheet
          sx={{
            borderRadius: 'md',
            mt: 4,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <DialogTitle>{title}</DialogTitle>
          <ModalClose disabled={!canClose} />
          {children}
        </Sheet>}
      </Drawer>
    </Box>
  );
}

