import { useNavigate } from "react-router-dom";


const authModule = await (process.env.REACT_APP_AUTHENTICATE_USERS === "NO" ? import('./null-user.js') : import('./firebase-user.js'));

export function useUser() {
  // we don't store these things in the DB, so have to grab from FB
  return { ...(authModule.getCurrentUser()), logout: async () => authModule.logOut(), reload: async () => authModule.reload() };
}


export function useCreateUser() {
  const navigate = useNavigate();
  return {
    createEmail: async ({ email, password }) => {
      await authModule.signUp(email, password);
      navigate("/login");
    },
    sendEmail: async () => {
      await authModule.sendVerificationEmail();
      navigate("/login");
    }

  };
}

export function useLogin() {
  const navigate = useNavigate();
  return {
    loginEmail: async ({ email, password }) => {
      await authModule.logIn(email, password);
      navigate("/");
    },
    passwordReset: async ({ email }) => {
      (authModule).sendResetEmail(email);
      navigate("/login");
    },
    loginGoogle: async () => {
      await authModule.signInWithGoogle();
      navigate("/");
    },
    loginGithub: async () => {
      await authModule.signInWithProvider('githubProvider');
      navigate("/");
    }
  };
}

