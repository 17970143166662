
import useAxios, { configure } from 'axios-hooks';
import {auth} from "./firebase";
import Axios from 'axios';
import LRU from 'lru-cache';

if (!process.env.REACT_APP_BACKEND_SERVER) {
  throw new Error(
    "No backend server, set REACT_APP_BACKEND_SERVER in server environment ;"
  );
}



const api = Axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_SERVER}/api`,
  method: "get"
});

const cache = new LRU({ max: 10 });
configure({ api, cache })


api.interceptors.request.use(async function (config) {
  let header = await getAuthHeader();
  header && Object.assign(config.headers, header);
  return config;
});


async function getAuthHeader() {
  let token = await auth?.currentUser?.getIdToken();
  return token && { authorization: `Bearer ${token}` };
}

export default useAxios;
export { useAxios, api, getAuthHeader };
