import * as React from 'react';
import image from '../../assets/converse.jpg';
import RightImagePage, { Typography} from '../layouts/RightImagePage';
import Button from '@mui/joy/Button';
import { ArrowForward } from '@mui/icons-material';
import GitHubIcon from '@mui/icons-material/GitHub';
import Link from '@mui/joy/Link';



export default function Interstitial() {
  return (
    <RightImagePage image={image} alt="Screenshot of app"
      sx={{
        my: 'auto',
        py: 2,
        pb: 5,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: 400,
        maxWidth: '100%',
        mx: 'auto',
        borderRadius: 'sm',
        '& form': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
      }}
    >

      <Typography level="title">
        What is this?
      </Typography>
      <Typography level="body">Experiment with platform independent prompted LLMs over the telephone with zero setup.</Typography>
      <Typography level="body">Choose any of the major recent commercial or open source large language models. Give it a prompt, perhaps using one of our examples as a template, then call the telephone number assigned to your agent to test out how it responds.</Typography>

      <Typography level="body">
        Created as a simple front end to our <Link href="https://github.com/aplisay/llm-agent">llm-agent</Link> open source tech, it is hosted in the UK and works from a dynamic pool of UK (+44) phone numbers. Conversation latency in other
        parts of the world will be pretty high and, depending on where you are, there may be significant international calling costs to reach the numbers it provides.
        Anyone anywhere in the world may however use the service within our <Link href="/privacy">Terms of Use</Link> for anything which is also legal in your own country.
      </Typography>
      <Link fontWeight="lg" href="/signup"><Button size="lg" endDecorator={<ArrowForward fontSize="xl" />}>
        Telephone an LLM
      </Button>
      </Link>
      <Typography level="heading">
        Commercial Use
      </Typography>
      <Typography level="body">
        This isn't a production service, it doesn't have the performance or reliability for anything other than personal experimentation. You can however use it to develop and prototype new prompts and function calling strategies, save the finished agent specification and deploy on a production server.
      </Typography>
      <Typography level="body">
        As well as all of the open source software needed, Aplisay provides commercial services to roll out high performance, resilient conversational AI infrastructure based on a wide range of base technologies. Please <Link href="https://aplisay.com/#contact" target="_blank" variant="outlined">Contact Us</Link> if you would like to explore these.
      </Typography>
      <Typography>
        Been here before? <Link fontWeight="lg" href="/login" variant="outlined">Sign in</Link>
      </Typography>
      <Typography startDecorator={<GitHubIcon />}>
        Back-end&nbsp;<Link fontWeight="lg" href="https://github.com/aplisay/llm-agent" target="_blank" variant="outlined">source code</Link>
      </Typography>
      </RightImagePage>
  );
}
