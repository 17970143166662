import React, { useEffect, useState } from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Input from '@mui/joy/Input';
import Checkbox from '@mui/joy/Checkbox';
import * as SwaggerParser from '@apidevtools/swagger-parser';
import ClearIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/joy/CircularProgress';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ModalClose from '@mui/joy/ModalClose';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Divider from '@mui/joy/Divider';

const METHODS = ['get', 'post', 'put', 'delete'];

const sanitiseText = (text) => text.substring(0, 1000);

export default function OASImport({
  functions,
  setFunctions,
  apiKeys,
  setApiKeys,
  open,
  setOpen,
  fromFile
}) {
  const [url, setUrl] = useState('');
  const [urlChecked, setUrlChecked] = useState(false);
  const [spec, setSpec] = useState(null);
  const [changed, setChanged] = useState(false);
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [fileDataUrl, setFileDataUrl] = useState(null);
  const [baseAPIServerURL, setBaseAPIServerURL] = useState('UNUSED');
  const [operations, setOperations] = useState([]);
  const [security, setSecurity] = useState([]);
  const [globalSecurity, setGlobalSecurity] = useState(); 
  const [filter, setFilter] = useState();


  const handleClose = () => {
    setOpen(false);
    setBaseAPIServerURL('');
  };

  const handleURLChange = (value) => {
    setUrl(value);
    setUrlChecked(false);
    setSpec(null);
    try {
      value?.length &&
        SwaggerParser.dereference(value)
          .then((api) => {
            console.log({ api }, 'api');
            setUrlChecked(true);
            setSpec(api);

          })
          .catch((e) => {
            setSpec(null);
            console.log(e);
            // Promise later rejects, still do nothing
          });
    } catch (e) {
      setSpec(null);
      console.log(e);
      // string doesn't parse do nothing
    }
  };

  const handleOperationChange = (operationId) => {
    setChanged(true);
    setSelectedOperations(prevOperations => {
      // Check if the operationId is already included in the selected operations
      if (prevOperations.includes(operationId)) {
        // If it is, filter it out (i.e., unselect it)
        return prevOperations.filter(id => id !== operationId);
      } else {
        // If it's not included, add it to the selected operations (i.e., select it)
        return [...prevOperations, operationId];
      }
    });
  };


  const handleUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = ['.json', '.yaml', '.yml'];
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target.result) {
          setFileDataUrl(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  useEffect(() => {
    fromFile && open && handleUpload();
  }, [fromFile, open]);

  useEffect(() => {
    console.log({ fileDataUrl }, 'useEffect fileDataUrl');
    if (!isValidUrl(fileDataUrl)) {
      return;
    }
    setUrl(fileDataUrl);
    setUrlChecked(false);
    setSpec(null);
    try {
      SwaggerParser.dereference(fileDataUrl)
        .then((api) => {
          console.log({ api }, 'api');
          setUrlChecked(true);
          setSpec(api);
        })
        .catch((e) => {
          setSpec(null);
          setFileDataUrl(null);
          console.log(e);
          // Promise later rejects, still do nothing
        });
    } catch (e) {
      setSpec(null);
      console.log(e);
      // string doesn't parse do nothing
    }
  }, [fileDataUrl]);

  useEffect(() => {
    if (spec) {
      let api = spec;
      let securitySchemes = api.components?.securitySchemes;
      let global = api.security?.length && Object.keys(api.security[0])[0];
      setGlobalSecurity(global);
      console.log({ securitySchemes, global, apiKeys, api_security: api.security[0], keys: Object.keys(api.security[0]) }, 'globalSecurity');
      Object.entries(securitySchemes).forEach(([name, sec]) => {
        console.log({ name, sec }, 'scheme');
        let keyName = api.info.title.replace(/[^a-z]/ig, '_') + name;
        sec.keyName = keyName;
        setApiKeys(prevApiKeys => {
          let { type, scheme } = sec;
          let thisKey = prevApiKeys.find((key) => key.name === keyName);
          thisKey = thisKey || prevApiKeys.push({ name: keyName });
          if (type === 'http' && (scheme === 'bearer' || scheme === 'basic')) {
            thisKey.in = scheme;
          }
          console.log({ prevApiKeys, type, scheme, thisKey }, 'New keys');
          return prevApiKeys;

        });
      });
      console.log({ securitySchemes }, 'setting global security state');
      setSecurity(securitySchemes);


      if (
        (!api.servers || api.servers.length === 0) &&
        !Object.keys(api.paths).every((path) => isValidUrl(path))
      ) {
        console.log({ servers: api.servers }, 'Set spec server Empty');
        setBaseAPIServerURL('');
      }
      console.log({ servers: api.servers?.length }, 'Set spec servers');
      if (api.servers?.length) {
        console.log(api.servers[0], 'Set spec server');
        setBaseAPIServerURL(api.servers[0].url);
      }
      const ops =
        api.paths &&
        Object.entries(api.paths).map(([path, methods]) => ({
          path,
          methods
        }));
      setOperations(ops);

    }
  }, [spec, apiKeys, setApiKeys]);

  const generateFunctionName = (path, method) => {
    const sanitizedPath = path
      .split('/')
      .reduce((o, v) => {
        return (
          o +
          (!v.match(/\{.+\}/)
            ? (o.length ? '_' : '') + v
            : `_param_${v.replace(/\{|\}/g, '')}`)
        );
      }, '')
      .replace(/[^0-9a-z_]/gi, '_');
    return `${method}_${sanitizedPath}`;
  };

  const isValidUrl = (string) => {
    if (!string) return false;

    try {
      new URL(string);
      return true;
    } catch (e) {
      // Allow localhost and other valid hostname formats without protocol - I assume axios is set to default to https TODO:check.
      const hostnamePattern = /^(localhost|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
      return hostnamePattern.test(string);
    }
  };

  const handleDone = () => {
    //The base URL based on the OpenAPI spec if theres only one in the spec or the provided base API server URL if NONE or more several.
    //NOTE if the path alone for any method is actually a valid full URL e.g https://api.example.com/method rather than /method then we don't use thsi.
    const baseUrl =
      (spec?.servers?.length === 1 ? spec.servers[0].url : baseAPIServerURL) ||
      '';

    const newFunctions = selectedOperations
      .map((operationId) => {
        try {
          // Ensure the operationId contains dashes to correctly split into path and method
          if (!operationId.includes('-')) {
            throw new Error(`Invalid operationId format: ${operationId}`);
          }

          const lastDashIndex = operationId.lastIndexOf('-');
          const path = operationId.substring(0, lastDashIndex);
          const method = operationId.substring(lastDashIndex + 1);
          const pathDetail = spec?.paths?.[path];

          if (!pathDetail || !pathDetail[method]) {
            // Handle case where path or method is undefined
            throw new Error(
              `Path or method not found for operationId: ${operationId}`
            );
          }

          const detail = pathDetail[method];
          const functionName = generateFunctionName(path, method);

          let parameters = (detail?.parameters || []).concat(
            spec?.paths?.[path]?.parameters || []
          );


          let keys = parameters
            .filter(({ name }) => name?.match(/api.*key/i))
            .map((p) => ({ ...p, type: 'key', schema: undefined }));

          parameters = parameters
            .filter(({ name }) => !name?.match(/api.*key/i))
            .map((p) => ({
              ...p,
              type: p.schema?.type === 'integer' ? 'number' : p.schema?.type,
              schema: undefined
            }))
            .concat(keys);

          // Ensure API keys are added to the state, TODO - check this works with proper security schema.
          keys.forEach((key) => {
            if (!apiKeys?.find((k) => k.name === key.name)) {
              setApiKeys([
                ...(apiKeys || []),
                { name: key.name, in: 'path', value: '' }
              ]);
            }
          });

          let s = detail.security?.[0]?.[0]?.name || globalSecurity
          console.log({ s, security, security_s: security[s], detail, globalSecurity }, 'Security');
          return {
            description: sanitiseText(detail.description || detail.summary),
            name: functionName,
            implementation: 'rest',
            url: isValidUrl(path) ? path : `${baseUrl}${path}`,
            parameters,
            keys,
            method,
            key: s && security[s]?.keyName
          };
        } catch (error) {
          console.error(error.message);
          return null;
        }
      })
      .filter(Boolean); // Filter out any null values

    setFunctions((prevFunctions) => [...prevFunctions, ...newFunctions]);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        sx={{
          width: '60%',
          maxHeight: '80%'
        }}
      >
        <ModalClose />

        <DialogTitle>Import from external OpenAPI spec</DialogTitle>
        {!fromFile && (
          <FormControl>
            <FormLabel>URL for OAS API spec</FormLabel>
            <Stack
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={1}
            >
              <Input
                variant='soft'
                color={urlChecked ? 'success' : 'primary'}
                value={url}
                onChange={(event) => handleURLChange(event?.target?.value)}
                endDecorator={urlChecked && <CheckIcon />}
                sx={{ flexGrow: 1 }}
              />
              <IconButton onClick={() => handleURLChange('')} color='danger'>
                <ClearIcon />
              </IconButton>
            </Stack>
            <FormHelperText>
              URL of a published Open API specification (JSON or YAML)
            </FormHelperText>
          </FormControl>
        )}
        {fromFile && fileDataUrl && !spec && (
          <CircularProgress
            color='primary'
            determinate={false}
            size='lg'
            variant='plain'
          />
        )}
        {spec && (!spec.servers || spec.servers.length === 0) && (
          <FormControl>
            <FormLabel>Manual Server URL</FormLabel>
            <Input
              variant='soft'
              value={baseAPIServerURL}
              onChange={(event) => setBaseAPIServerURL(event.target.value)}
              placeholder='Enter server URL'
              error={
                !isValidUrl(baseAPIServerURL) && baseAPIServerURL.length > 0
              }
            />
            <FormHelperText>
              Provide the server URL if not specified in the OpenAPI spec
            </FormHelperText>
          </FormControl>
        )}
        {spec && spec.servers && spec.servers.length > 1 && (
          <FormControl>
            <FormLabel>Select Server URL</FormLabel>
            <Select
              value={baseAPIServerURL}
              onChange={(e, newValue) => {
                setBaseAPIServerURL(newValue);
              }}
            >
              {spec.servers.map((server, index) => (
                <Option key={index} value={server.url}>
                  {server.url}
                </Option>
              ))}
            </Select>
            <p>{console.log({ baseAPIServerURL }, 'render')}</p>
          </FormControl>
        )}
        <Divider />
        <Input value={filter} onChange={(e) => setFilter(e.target.value)} />
        
        <List
          sx={{
            overflowY: 'auto',
            overflowX: 'clip',
            mx: 'calc(-1 * var(--ModalDialog-padding))',
            px: 'var(--ModalDialog-padding)'
          }}
        >
          {operations?.map((operation) => {
            let path = operation.path;
            const baseUrl =
              spec && spec.servers && spec.servers.length === 1
                ? spec.servers[0].url
                : baseAPIServerURL || '';
            let fullUrl = isValidUrl(path) ? path : `${baseUrl}${path}`;
            return Object.entries(operation.methods)
              .filter(([method]) => METHODS.indexOf(method) >= 0)
              .map(([method, detail]) => {
                let operationId = `${operation.path}-${method}`;
                let functionName = generateFunctionName(operation.path, method);
                let existing = functions.find((f) => f.name === functionName);
                let preExisting = existing && existing.url !== fullUrl;
                //console.log({ fullUrl, isvalid: isValidUrl(fullUrl), path, baseUrl });
                return (
                  (!filter?.length || functionName.includes(filter))
                  && <ListItem key={operationId}>
                    <FormControl error={preExisting}>
                      <Checkbox
                        label={`${operation.path}: ${functionName}`}
                        disabled={!isValidUrl(fullUrl) || preExisting}
                        checked={selectedOperations.includes(operationId)}
                        onChange={() => handleOperationChange(operationId)}
                      />
                      {preExisting && (
                        <FormHelperText error>
                          {functionName} already exists, remove it to add this
                          one!
                        </FormHelperText>
                      )}
                    </FormControl>
                  </ListItem>
                );
              });
          })}
        </List>
        <Tooltip
          title={
            !isValidUrl(baseAPIServerURL) ? 'Please add a valid server URL' : ''
          }
        >
          <span>
            <Button
              variant='solid'
              disabled={!changed || !isValidUrl(baseAPIServerURL)}
              startDecorator={<DoneIcon />}
              onClick={handleDone}
              sx={{ m: 2 }}
            >
              Done
            </Button>
          </span>
        </Tooltip>
      </ModalDialog>
    </Modal>
  );
}